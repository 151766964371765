import { Offcanvas, Tab } from '../../node_modules/bootstrap/dist/js/bootstrap.esm.min.js';
import Splide from '@splidejs/splide';

import { MenuTrigger } from "./menuTrigger.js";
import { FilterSearch } from './filterSearch.js';
import { QuantityControl } from './quantityControl.js';
import { PreviewProductImages } from './productPreviewImages.js';
import { MainNavigation } from './mainNavigation.js';
import { SubCategoriesMenuTrigger } from './subCategoriesMenuTrigger.js';

class App {
    filterMenu;
    categoriesMenu;

    constructor() {
        new MainNavigation();

        Array.from(document.querySelectorAll('[data-opens="subsection-menu"]'))
            .forEach((trigger) => {
                new SubCategoriesMenuTrigger(trigger)
            })

        Array.from(document.querySelectorAll('.menu-trigger'))
            .forEach(trigger => new MenuTrigger(trigger));

        Array.from(document.querySelectorAll('.offcanvas'))
            .forEach(offcanvas => new Offcanvas(offcanvas));

        Array.from(document.querySelectorAll('#cartItems button'))
            .forEach(triggerEl => {
                const tabTrigger = new Tab(triggerEl);

                triggerEl.addEventListener('click', event => {
                    event.preventDefault();
                    tabTrigger.show();
                })
            });

        Array.from(document.querySelectorAll('input[data-search-for]'))
            .forEach(filterSearch => new FilterSearch(filterSearch));

        Array.from(document.querySelectorAll('.quantity-control'))
            .forEach(form => new QuantityControl(form));

        Array.from(document.querySelectorAll('.product-preview-gallery'))
            .forEach(element => new PreviewProductImages(element));

        Array.from(document.querySelectorAll('.splide'))
            .forEach(carousel => new Splide(carousel, {
                pagination: false,
                autoWidth: carousel.classList.contains('splide-auto-width'),
            }).mount());
    }
}

const app = new App();