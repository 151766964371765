export class MenuTrigger {
    trigger;
    menu;
    backdrop;
    close;
    subsectionMenus;

    constructor(trigger) {
        this.trigger = trigger;
        this.subsectionMenus = document.querySelectorAll('.mobile-menu-slide');
        this.menu = document.querySelector(this.trigger.dataset.menu);
        this.backdrop = document.querySelector(this.trigger.dataset.backdrop);
        this.close = document.querySelector(this.trigger.dataset.close);

        this.trigger.addEventListener('click', () => {
            this.menu.style.display === 'none' ?
                this.show() :
                this.hide();
        });

        this.backdrop.addEventListener('click', this.hide.bind(this));
        this.close.addEventListener('click', this.hide.bind(this));
    }

    show() {
        this.backdrop.style.display = 'block';
        this.menu.style.display = 'block';
        this.close.style.display = 'inline-block';

        if (window.innerWidth > 767) {
            const boundingClientRect = this.trigger.getBoundingClientRect();
            this.menu.style.top = `${boundingClientRect.top + boundingClientRect.height}px`;

            if (this.trigger.dataset.positionRight) {
                this.menu.style.right = this.trigger.dataset.positionRight;
                this.menu.style.left = null;
            } else {
                this.menu.style.left = `${boundingClientRect.left}px`;
                this.menu.style.right = null;
            }

            if (this.trigger.dataset.menuWidth) {
                this.menu.style.width = this.trigger.dataset.menuWidth;
            } else {
                this.menu.style.width = 'auto';
            }

            this.menu.style.height = 'auto';
        } else {
            document.body.style.overflow = 'hidden';
            this.menu.style.top = '0';
            this.menu.style.left = '0';
            this.menu.style.width = '100vw';
            this.menu.style.height = '100vh';
        }

        setTimeout(() => {
            this.menu.style.opacity = '1';
        });
    }

    hide() {
        this.backdrop.style.display = 'none';
        this.close.style.display = 'none';
        this.menu.style.opacity = '0';
        document.body.style.overflow = 'visible';
        setTimeout(() => {
            this.menu.style.display = 'none';
        }, 300);

        this.subsectionMenus.forEach(menu => {
            menu.classList.remove('show');
            // menu.classList.remove('active');
        });
    }
}