export class SubCategoriesMenuTrigger {
    element;
    menu;
    goback;

    constructor (element) {
        this.element = element;
        this.menu = document.querySelector(element.dataset.target);

        if (!this.menu) return;

        this.element.addEventListener('click', this.open.bind(this));

        this.goback = this.menu.querySelector('.go-back');
        this.goback.addEventListener('click', this.close.bind(this));
    }

    open() {
        if (!this.menu) return;
        this.menu.classList.add('show');
    }

    close() {
        if (!this.menu) return;
        this.menu.classList.remove('show');
    }
}