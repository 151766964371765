export class MainNavigation {
    subsectionListLinks;
    innerPanels;

    constructor() {
        this.subsectionListLinks = document.querySelectorAll('.subsection-list a');
        this.innerPanels = document.querySelectorAll('.inner-section-panel');

        this.subsectionListLinks.forEach(element => {
            element.addEventListener('mouseover', (e) => {
                this.hideInnerSectionPanels();
                this.setSubsectionLinksInactive();

                const targetId = element.dataset.target;
                const target = document.querySelector(targetId);
                target.style.display = 'block';

                element.classList.add('active');
            });
        })
    }

    hideInnerSectionPanels () {
        this.innerPanels.forEach(element => element.style.display = 'none');
    }

    setSubsectionLinksInactive () {
        this.subsectionListLinks.forEach(element => element.classList.remove('active'));
    }
}